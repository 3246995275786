
body {
  background-color: #ffe4c4;
  color: #000;
  font-family: 'Inknut Antiqua', serif;
}

img {
  max-width: 100%;
  max-height: 100%;
}

h1 {
  font-weight: 700;
  font-size: 62px;
}

h2 {
  font-weight: 700;
  font-size: 32px;
}

p {
  font-size: 21px;
  line-height: 1.5;
  font-weight: 500;
}

header {
  margin: 50px 20px 10px 20px;
  padding-bottom: 40px;
  border-bottom: 5px solid #000;
}

section {
  margin: 25px 20px;
  max-width: 1000px;
  border-bottom: 5px solid #000;
}

section:first-of-type {
  border-bottom: 0;
}

footer {
  margin: 20px 10px 10px 10px;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: underline;
}

.logo {
  position: relative;
  bottom: 25px;
  float: left;
  margin-right: 10px;
  width: 140px;
  height: 140px;
}

@media (max-width: 489px) {
  header {
    padding-bottom: 10px;
  }

  h1 {
    font-size: 42px;
    text-align: center;
    margin: 0;
  }

  h2 {
    font-size: 28px;
  }

  .logo {
    float: none;
    width: 80px;
    height: 80px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 490px)  and (max-width: 546px) {
  header {
    padding-bottom: 10px;
  }

  h1 {
    font-size: 52px;
  }
}

@media (min-width: 547px)  and (max-width: 773px) {
  header {
    padding-bottom: 10px;
  }

  .logo {
    bottom: 5px;
  }
}

.about {
  text-transform: uppercase;
}

::-moz-selection {
  background: #0ff;
}

::selection {
  background: #0ff;
}
